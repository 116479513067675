// CHANGES - added null check for map variable
if (L.Marker) {
    L.Marker.prototype._animateZoom = function (opt) {
        if (!this._map) {
            return;
        }
        var pos = this._map._latLngToNewLayerPoint(this._latlng, opt.zoom, opt.center).round();
        this._setPos(pos);
    };
}

if (L.Tooltip) {
    L.Tooltip.prototype._animateZoom = function (e) {
        if (!this._map) {
            return;
        }
        var pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center);
        this._setPosition(pos);
    };

    L.Tooltip.prototype._updatePosition = function () {
        if (!this._map) {
            return;
        }
        var pos = this._map.latLngToLayerPoint(this._latlng);
        this._setPosition(pos);
    };
}

if (L.Popup) {
    L.Popup.prototype._animateZoom = function (e) {
        if (!this._map || typeof L.DomUtil === 'undefined') {
            return;
        }
        var pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center),
            anchor = this._getAnchor();
        L.DomUtil.setPosition(this._container, pos.add(anchor));
    };
}

if (L.Rrose) {
    L.Rrose.prototype._updatePosition = function () {
        if (!this._map) {
            return;
        }
        var pos = this._map.latLngToLayerPoint(this._latlng),
            is3d = L.Browser.any3d,
            offset = this.options.offset;

        if (is3d) {
            L.DomUtil.setPosition(this._container, pos);
        }

        if (/s/.test(this.options.position)) {
            // CHANGED - added popup height bottom pos calculation
            this._containerBottom =
                -Math.round(this._containerHeight) - this._container.offsetHeight + offset.y - (is3d ? 0 : pos.y);
        } else {
            this._containerBottom = -offset.y - (is3d ? 0 : pos.y);
        }

        if (/e/.test(this.options.position)) {
            this._containerLeft = offset.x + (is3d ? 0 : pos.x);
        } else if (/w/.test(this.options.position)) {
            this._containerLeft = -Math.round(this._containerWidth) + offset.x + (is3d ? 0 : pos.x);
        } else {
            this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x + (is3d ? 0 : pos.x);
        }

        this._container.style.bottom = this._containerBottom + 'px';
        this._container.style.left = this._containerLeft + 'px';
    };
}

if (L.Layer) {
    L.Layer.prototype._addFocusListenersOnLayer = function (layer) {
        var el = typeof layer.getElement === 'function' && layer.getElement();
        if (el && el._tooltip) {
            L.DomEvent.on(
                el,
                'focus',
                function () {
                    this._tooltip._source = layer;
                    this.openTooltip();
                },
                this,
            );
            L.DomEvent.on(el, 'blur', this.closeTooltip, this);
        }
    };
}

if (L.FeatureGroup) {
    L.FeatureGroup.prototype._getExpandedVisibleBounds = function () {
        if (!this.options.removeOutsideVisibleBounds) {
            return this._mapBoundsInfinite;
        } else if (L.Browser.mobile) {
            return this._checkBoundsMaxLat(this._map.getBounds());
        }

        /* Changed: removed map bounds expansion */
        return this._checkBoundsMaxLat(this._map.getBounds());
    };
}

// Leaflet FreeDraw - support for touch events
if (L.Map) {
    L.Map.prototype.fixTouchEvents = function () {
        const container = this.getContainer();
        // Touch start event
        container.addEventListener(
            'touchstart',
            e => {
                const touch = e.changedTouches[0];
                const simulatedEvent = new MouseEvent('mousedown', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                });
                touch.target.dispatchEvent(simulatedEvent);
                // e.preventDefault();
            },
            { passive: false },
        );
        // Touch move event
        container.addEventListener(
            'touchmove',
            e => {
                const touch = e.changedTouches[0];
                const simulatedEvent = new MouseEvent('mousemove', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                });
                touch.target.dispatchEvent(simulatedEvent);
                e.preventDefault();
            },
            { passive: false },
        );
        // Touch end event
        container.addEventListener(
            'touchend',
            e => {
                const touch = e.changedTouches[0];
                const simulatedEvent = new MouseEvent('mouseup', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                });
                touch.target.dispatchEvent(simulatedEvent);
                // e.preventDefault();
            },
            { passive: false },
        );
    };
}
